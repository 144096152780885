
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { useI18n } from "vue-i18n";
import { modalShowListener, showModal } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-entity-role-modal",
  props: {
    lastBillingDate: {
      type: String,
      default: "",
    },
  },
  emits: ["modal-hide", "modal-submit"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const setBusinessDateModalRef = ref<null | HTMLElement>(null);

    const formData = ref({
      business_date: "",
    });

    const rules = ref({
      business_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const disabledDate = (time: Date) => {
      const startDate = new Date(props.lastBillingDate);
      return (
          time.getTime() > Date.now() || time.getTime() <= startDate.getTime() - 86400000
      );
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          emit("modal-submit", formData.value);
          modalHide();
        } else {
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const modalShow = () => {
      showModal(setBusinessDateModalRef.value);
    };

    const modalHide = () => {
      hideModal(setBusinessDateModalRef.value);
    };

    onMounted(() => {
      modalShowListener(setBusinessDateModalRef.value, () => {
        setTimeout(() => {
          //
        }, 0);
      });
      modalHideListener(setBusinessDateModalRef.value, () => {
        resetForm();
        emit("modal-hide");
      });
    });

    return {
      t,
      props,
      formData,
      rules,
      formRef,
      setBusinessDateModalRef,
      modalShow,
      modalHide,
      submit,
      resetForm,
      disabledDate,
    };
  },
});
